<template>
  <div>
    <el-card header="商品数据" class="page_section_card">
      <keep-alive>
        <dataFilterCompVue
          ref="searchComp"
          :isType="true"
          @search="search"
        ></dataFilterCompVue>
      </keep-alive>
    </el-card>
    <el-card v-loading="ratioLoading" class="page_section_card">
      <div class="display_flex ratio_table">
        <div
          class="ratio_item"
          v-for="item in goodsCols"
          :key="item.label"
          v-show="item.toThunsands && item.prop != 'product_name'"
        >
          <p>{{ item.label }}</p>
          <p>{{ common.formatNumber(ratioData[item.prop]) || "--" }}</p>
          <p>
            环比
            <i
              :class="
                ratioData[item.prop + '_ratio'] > 0
                  ? 'el-icon-top up'
                  : 'el-icon-bottom down'
              "
            ></i>
            <i :class="ratioData[item.prop + '_ratio'] > 0 ? 'up' : 'down'">{{
              ratioData[item.prop + "_ratio"]
            }}</i>
          </p>
        </div>
      </div>
    </el-card>
    <el-card v-loading="loading">
      <div
        slot="header"
        class="display_flex justify_content_space_between align_item_center"
      >
        <span>报表</span>
        <div>
          <el-button type="primary" icon="el-icon-download" @click="download()"
            >下载报表</el-button
          >
        </div>
      </div>
      <el-table
        ref="tableList"
        class="lazyLoadList"
        :data="tableList"
        border
        stripe
        @sort-change="sortChange"
        :row-key="getRowKey"
        lazy
        :load="load"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column
          v-for="item in goodsCols"
          :key="item.label"
          :label="item.label"
          :prop="Array.isArray(item.prop) ? '' : item.prop"
          :align="item.align"
          :min-width="item.width"
          :fixed="item.fixed"
          :sortable="item.sortable"
        >
          <template slot="header" v-if="item.tooltip">
            <el-tooltip effect="dark" :content="item.tooltip" placement="top">
              <span class="el-icon-warning"></span>
            </el-tooltip>
            {{ item.label }}
          </template>
          <template slot-scope="scope">
            {{
              common.formatNumber(scope.row[item.prop], item.toThunsands || 2)
            }}
          </template>
        </el-table-column>
      </el-table>
      <pagination-vue
        @sizeChange="sizeInit"
        @currentChange="getList"
        :pageSize="postdata.page_num"
        :total="total"
        :firstPage="firstPage"
      />
    </el-card>
  </div>
</template>
<script>
import goodsCols from "@/assets/mcnTableCols/goodsCols.js";
import paginationVue from "@/components/paginationVue.vue";
import dataFilterCompVue from "./components/dataFilterComp.vue";
export default {
  components: {
    dataFilterCompVue,
    paginationVue,
  },
  data() {
    return {
      ratioData: {},
      goodsCols: goodsCols.reportCols,
      loading: false,
      ratioLoading: false,
      last_time: "",
      postdata: {
        page: 1,
        page_num: 20,
      },
      tableList: [],
      total: 0,
      firstPage: 1,
      token: localStorage.getItem("token"),
    };
  },
  mounted() {
    this.$refs.searchComp.search();
  },
  methods: {
    download() {
      window.open(
        `${this.$pythonHost}productData/data/product/download?creator_name=${this.postdata.creator_name}&start_date=${this.postdata.start_date}&end_date=${this.postdata.end_date}`
      );
    },
    async load(tree, treeNode, resolve) {
      let res = await this.getDateList(tree);
      let dateList = res.data.map((item) => {
        item.name = item.date;
        return item;
      });
      resolve(dateList);
    },
    getRowKey(row) {
      if (row.date) {
        return row.creator_name + "_" + row.date;
      } else {
        return row.creator_name;
      }
    },
    getDateList(row) {
      let pd = { ...this.postdata };
      pd.creator_id = row.creator_id;
      pd.creator_name = row.creator_name;
      pd.page_num = 1000;
      return new Promise((resolve, reject) => {
        this.axios(
          "GET",
          `${this.$pythonHost}productData/data/product/detail`,
          pd
        )
          .then((res) => {
            if (res.code == 1) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    sortChange({ column, prop, order }) {
      if (order) {
        this.order = prop;
        this.rank = order == "ascending" ? "asc" : "desc";
        this.tableList = [];
        this.getList(1);
      } else {
        // this.defaultSort = { prop: 'zongcost', order: 'ascending' }
      }
    },
    search(data) {
      this.postdata = Object.assign(this.postdata, data);
      this.postdata.page = 1;
      this.getList(1);
      this.getRatioData();
    },
    getRatioData() {
      if (this.ratioLoading) {
        return;
      }
      this.ratioLoading = true;
      this.axios(
        "get",
        `${this.$pythonHost}productData/data/product/gather`,
        this.postdata
      )
        .then((res) => {
          this.ratioLoading = false;
          if (res.code == 1) {
            this.ratioData = res.data.data;
          }
        })
        .catch((error) => {
          this.ratioLoading = false;
        });
    },
    getList(page) {
      if (this.loading) {
        return;
      }
      this.tableList = [];
      this.loading = true;
      this.postdata.page = page || this.firstPage;
      const pd = { ...this.postdata, order: this.order, rank: this.rank };
      this.axios("get", `${this.$pythonHost}productData/data/product/list`, pd)
        .then((res) => {
          this.loading = false;
          if (res.code == 1) {
            this.total = res.data.total_count;
            this.firstPage = res.data.page;
            this.tableList = res.data.data.map((item) => {
              item.name = item.creator_name;
              item.hasChildren = true;
              item.children = [];
              return item;
            });
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    sizeInit(size) {
      this.postdata.page_num = size || 20;
      this.getList(1);
    },
  },
};
</script>
