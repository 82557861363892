const columns = [
  {
    label: "日期",
    prop: "date",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: "left",
  },
  {
    label: "达人昵称(英文)",
    prop: "creator_handle",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "达人昵称(原文)",
    prop: "creator_name",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "商品ID",
    prop: "product_id",
    align: "center",
    width: 100,
    tooltip: "",
    sortable: false,
    fixed: false,
    noFormat: true,
  },
  {
    label: "商品信息",
    prop: "product_name",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "店铺名称",
    prop: "shop_name",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  // {
  //   label: "一级类目",
  //   prop: "",
  //   align: "center",
  //   width: 130,
  //   tooltip: "",
  //   sortable: false,
  //   fixed: false,
  // },
  // {
  //   label: "二级类目",
  //   prop: "",
  //   align: "center",
  //   width: 130,
  //   tooltip: "",
  //   sortable: false,
  //   fixed: false,
  // },
  {
    label: "成交金额(฿)",
    prop: "revenue_amount",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "成交件数",
    prop: "items_sold_cnt",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "收入(直播)(฿)",
    prop: "revenue_amount_by_live",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "收入(视频)(฿)",
    prop: "revenue_amount_by_video",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "收入(商品橱窗)(฿)",
    prop: "revenue_amount_by_showcase",
    align: "center",
    width: 150,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "退款(฿)",
    prop: "refund_amount",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "订单数-视频",
    prop: "orders_cnt_video",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "订单数-直播",
    prop: "orders_cnt_live",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "订单数-商品橱窗",
    prop: "orders_cnt_showcase",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "成交件数-直播",
    prop: "items_sold_cnt_live",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "成交件数-视频",
    prop: "items_sold_cnt_video",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "退款商品数",
    prop: "refund_items_sold_cnt",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "成交件数-商品橱窗",
    prop: "items_sold_cnt_showcase",
    align: "center",
    width: 150,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "播放量",
    prop: "views_cnt",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "点击-成交转化率(%)",
    prop: "click_transaction_conversion_rate",
    align: "center",
    width: 160,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "商品点击数",
    prop: "click_cnt",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "看播-点击转化率(%)",
    prop: "views_click_conversion_rate",
    align: "center",
    width: 160,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "投诉率(%)",
    prop: "complaint_rate",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "差评率(%)",
    prop: "bad_eval_rate",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "品退率(%)",
    prop: "return_rate",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
];

const reportCols = [
  {
    label: "达人信息",
    prop: "name",
    align: "left",
    width: 200,
    tooltip: "",
    sortable: false,
    fixed: "left",
  },
  {
    label: "主推商品",
    prop: "product_name",
    align: "center",
    width: 150,
    tooltip: "周期内达人销量额最高的商品",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "带货商品数量",
    prop: "good_num",
    align: "center",
    width: 160,
    tooltip: "达人带货的商品种类数量",
    sortable: "custom",
    fixed: false,
    toThunsands: 0,
  },
  {
    label: "商品总GMV($)",
    prop: "revenue_amount_sum",
    align: "center",
    width: 170,
    tooltip: "商品成交金额的汇总",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "预估整体商品利润($)",
    prop: "institutional_total_commission_sum",
    align: "center",
    width: 200,
    tooltip: "预估商品销售的总利润",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "TAP GMV($)",
    prop: "estimated_commission_amount_sum",
    align: "center",
    width: 170,
    tooltip: "达人TAP 预估佣金总额",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "TAP GMV占比(%)",
    prop: "tap_gmv_percent",
    align: "center",
    width: 200,
    tooltip: "TAP GMV / 商品总 GMV * 100%",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "预估TAP利润($)",
    prop: "tap_estimated_commission_amount_sum",
    align: "center",
    width: 200,
    tooltip: "预计联盟合作伙伴获得佣金",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "TAP 利润占比(%)",
    prop: "tap_profit_percent",
    align: "center",
    width: 200,
    tooltip: "预估 TAP 利润 / 预估整体商品利润",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "TAP 商品数量",
    prop: "tap_good_num",
    align: "center",
    width: 160,
    tooltip: "机构中产生的商品种类",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
];
export default {
  columns,
  reportCols,
};
